import React from 'react'

import Layout from '../components/organisms/Layout/Layout'
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from '../components/atoms/SEO/SEO'

const Coaching = () => (
    <Layout>
        <SEO title="Allgemeine Geschäftsbedingungen" />
        <PageWidth isContent={true}>
            <h1>Allgemeine Geschäfts&shy;bedingungen</h1>
            <p>Karl Ludwig Weise, Grevenweg 80, 20537 Hamburg, Deutschland</p>
            <ol className="legal-list">
                <li>Geltungsbereich
                    <ol>
                        <li>
                            Gegenstand dieses Vertrages sind meine standardisierten und individuellen Webdesignleistungen sowie die Bereitstellung von Webspace durch mich, die Anbindung Ihrer Website an das Internet sowie je nach Leistungspaket weiterer Dienstleistungen im Bereich Hosting.
                        </li>
                        <li>
                            Die nachfolgenden Geschäftsbedingungen gelten für die gesamte Geschäftsbeziehung zwischen Ihnen und mir, soweit Sie Unternehmer im Sinne des § 14 BGB oder eine juristische Person des öffentlichen Rechts sind.
                        </li>
                        <li>
                            Entgegenstehende oder von diesen Geschäftsbedingungen abweichende AGB erkennte ich nicht an, es sei denn, der Geltung dieser AGB stimme ich ausdrücklich zu. Dies gilt auch dann, wenn die Angebotsabgabe oder -annahme durch Sie unter dem Hinweis der vorrangigen Geltung der eigenen Allgemeinen Geschäftsbedingungen erfolgt.
                        </li>
                        <li>
                            Diese Geschäftsbedingungen gelten in der jeweils aktuellen Fassung auch für alle zukünftigen Geschäfte mit Ihnen.
                        </li>
                    </ol>
                </li>
                <li>Vertragsschluss
                    <ol>
                        <li>
                            Meine Website im Internet dient zu Informationenzwecken über die angebotenen Leitungen. Ein direkter Vertragsschluss über die Website erfolgt nicht.
                        </li>
                        <li>
                            Ich lege Ihnen nach Kontaktaufnahme ein Angebot persönlich, per E-Mail oder Brief vor. Ein Vertrag kommt zustande, wenn Sie dieses Angebot annehmen und die Annahme per E-Mail, Fax oder per Post bei mir bestätigten.
                        </li>
                        <li>
                            Meine Angebote sind, sofern nicht anders angegeben, freibleibend. An fixe Angebote halte ich mich in Ermangelung anderweitiger Bestimmung zwei (2) Wochen gebunden, maßgeblich ist der Zeitpunkt der Abgabe.
                        </li>
                        <li>
                            Ein genereller Anspruch auf Vertragsschluss besteht nicht. Ich behalte mir vor, den Vertragsschluss ohne Angabe von Gründen zu verweigern.
                        </li>
                        <li>
                            Ihnen wird kein Eigentum und keine Nutzungsrechte an Zeichnungen, Entwürfen, Layouts, Software und sonstigen Materialien und Unterlagen eingeräumt, die im Rahmen von Angeboten und Vertragsverhandlungen übergeben werden. Die Weitergabe an Dritte bedarf meiner ausdrücklichen Zustimmung.
                        </li>
                    </ol>
                </li>
                <li>Leistungen
                    <ol>
                        <li>
                            Die Einzelheiten der von mir für Sie zu erbringenden Leistung ergeben sich aus meinem Angebot.
                        </li>
                        <li>
                            Ohne gesonderte Vereinbarung bin ich nicht zur Herausgabe von zur vertraglichen Leistung führenden Zwischenergebnissen, Entwürfen, Layouts, Quelldateien etc. verpflichtet.
                        </li>
                        <li>
                            Ich bin zu Teilleistungen berechtigt, soweit diese Ihnen zumutbar sind.
                        </li>
                        <li>
                            Ohne gesonderte Vereinbarung ist die patent-, muster-, urheber- und markenrechtliche Schutz- oder Eintragungsfähigkeit der im Rahmen des Vertrages gelieferten Ideen, Anregungen, Vorschläge, Konzeptionen, Entwürfe und sonstiger Leistungen nicht geschuldet.
                        </li>
                    </ol>
                </li>
                <li>Hosting
                    <ol>
                        <li>
                            Der Leistungsumfang richtet sich nach den jeweils gewählten Paketen. Die technischen Spezifikationen und Leistungen sind im übermittelten Angebot detailliert dargestellt.
                        </li>
                        <li>
                            Ich überlasse Ihnen Speicherplatz auf einem Server zur Nutzung, der zur Speicherung der übermittelten Inhalte geeignet ist. Bei diesem Server handelt es sich um Server eines Dritten, zu dessen Nutzung ich berechtigt bin.
                        </li>
                        <li>
                            Vertragsgegenstand ist zudem die Anbindung Ihrer Website an das Internet sowie in Abhängigkeit von dem jeweils gewählten Paket, das Registrieren und Konnektieren einer oder mehrerer Domains.
                        </li>
                    </ol>
                </li>
                <li>Domainregistrierung
                    <ol>
                        <li>
                            Bei der Verschaffung und Pflege von Domains bin ich im Verhältnis zwischen Ihnen und dem jeweiligen Registrar lediglich als Vermittler tätig. Im Rahmen der Domainregistrierung gelten die jeweiligen Bedingungen des Registrars für die Registrierung und Verwaltung von Top-Level-Domains sowie der entsprechenden Sub-Level-Domains.
                        </li>
                        <li>
                            Ich habe auf die Domain-Vergabe keinen Einfluss und kann keine Gewähr dafür übernehmen, dass die für Sie beantragten Domains zugeteilt werden können oder frei von Rechten Dritter sind.
                        </li>
                        <li>
                            Sie haben dafür Sorge zu tragen, dass die von Ihnen beantragten Domains keine Rechte Dritter wie Namensrechte oder Markenrechte verletzten.
                        </li>
                    </ol>
                </li>
                <li>Laufzeit des Vertrages/ Kündigung
                    <ol>
                        <li>
                            Verträge im Bereich Hosting werden ohne weitergehende Vereinbarungen für die Dauer von der im Angebot angegebenen Laufzeit geschlossen. Der Vertrag verlängert sich automatisch um die gewählte Laufzeit, wenn nicht 14 Tage vor Ablauf der Vertragslaufzeit gekündigt wird. Die Kündigung kann schriftlich auf dem Postweg sowie per Fax oder E-Mail erfolgen.
                        </li>
                        <li>
                            Das Kündigungsrecht aus wichtigem Grund bleibt hiervon unberührt. Ein wichtiger Grund liegt für mich insbesondere entsprechend der §§ 543 Abs. 2 Nr. 2, 3 BGB vor. Ein wichtiger Grund kann insbesondere auch dann vorliegen, wenn Sie die unter Nr. 7 benannten Pflichten schuldhaft verletzten.
                        </li>
                        <li>
                            Ich bin berechtigt, Ihre Domain nach wirksamer Beendigung des Vertrages gegenüber dem zuständigen Registrar freizugeben. Mit der Freigabe erlöschen alle Ihre Rechte aus der Registrierung der Domain.
                        </li>
                        <li>
                            Im Falle des Providerwechsels steht mir ein Zurückbehaltungsrecht im Hinblick auf die Freigabeerklärung gegenüber dem neuen Provider zu, soweit Sie die vertragsgemäß geschuldete und fällige Vergütung noch nicht oder nicht vollständig erbracht hat.
                        </li>
                    </ol>
                </li>
                <li>Mitwirkungsleistungen
                    <ol>
                        <li>
                            Sie unterstützten mich bei der Erfüllung der vertraglich geschuldeten Leistungen. Dazu gehört insbesondere die rechtzeitige Bereitstellung von Informationen, Materialien, Daten ("Inhalte") sowie von Hard- und Software, soweit Ihre Mitwirkungsleistungen dies erfordern.
                        </li>
                        <li>
                            Von Ihnen bereitzustellende Inhalte sind in einem gängigen, unmittelbar verwertbaren, digitalen Format zur Verfügung zu stellen. Ist eine Konvertierung der von Ihnen überlassenen Inhalte in ein anderes Format erforderlich, so übernehmen Sie nach Rücksprache die hierfür anfallenden Kosten nach meinem üblichen Stundensatz.
                        </li>
                        <li>
                            Erkennen Sie, dass eigene Angaben, Anforderungen oder Inhalte fehlerhaft, unvollständig, nicht eindeutig oder nicht durchführbar sind, haben Sie dies mir unverzüglich mitzuteilen.
                        </li>
                        <li>
                            Ihre Mitwirkungsleistungen, die im Rahmen des Vertrages geschuldet sind, erfolgen ohne besondere Vergütung, es sei denn, es ist ausdrücklich etwas anderes vereinbart.
                        </li>
                    </ol>
                </li>
                <li>Leistungsänderungen
                    <ol>
                        <li>
                            Wünschen Sie eine Änderung des vertraglich bestimmten Umfangs der Leistungen, so teilen Sie mir das bitte schriftlich mit. Ich werde Ihren Änderungswunsch und die Auswirkungen auf die bestehende Vereinbarung prüfen.
                        </li>
                        <li>
                            Ist die Änderung nach dem Ergebnis der Prüfung durchführbar, werden wir uns bezüglich des Inhalts des Vorschlags für die Umsetzung des Änderungswunsches abstimmen. Kommt eine Einigung zustande, wird der Vertrag insoweit geändert. Kommt keine Einigung zustande, so verbleibt es beim ursprünglichen Leistungsumfang.
                        </li>
                        <li>
                            Vereinbarte Termine werden, wenn und soweit sie vom Änderungsverfahren betroffen sind, unter Berücksichtigung der Dauer der Prüfung, der Abstimmung über den Änderungsvorschlag und gegebenenfalls der auszuführenden Änderungswünsche zuzüglich einer angemessenen Anlauffrist verschoben. Ich werde Ihnen die neuen Termine mitteilen.
                        </li>
                    </ol>
                </li>
                <li>Kundeninhalte, Gestaltungsfreiheit, Freigabe
                    <ol>
                        <li>
                            Es besteht keine Prüfungspflicht bezüglich der von Ihnen übermittelten Inhalte, wie Bildern oder Texten; insbesondere im Hinblick auf urheberrechtliche, namensrechtliche oder markenrechtliche Vorgaben. Eine Haftung für diesbezügliche Rechtsverstöße ist ausgeschlossen.
                        </li>
                        <li>
                            Soweit Sie in Bezug auf die Ausführung keine konkreten Vorgaben machen, besteht für mich im Rahmen des erteilten Auftrags Gestaltungsfreiheit.
                        </li>
                        <li>
                            Nach meiner Aufforderung sind Sie zur Freigabe auch von Entwürfen und Zwischenergebnissen verpflichtet, sofern diese für sich sinnvoll beurteilt werden können.
                        </li>
                    </ol>
                </li>
                <li>Termine
                    <ol>
                        <li>
                            Leistungsverzögerungen aufgrund von Umständen in Ihrem Verantwortungsbereich (z.B. nicht rechtzeitige Erbringung von Mitwirkungsleistungen) und höherer Gewalt (z. B. Streik, Aussperrung, allgemeine Störungen der Telekommunikation) hae ich nicht zu vertreten. Sie berechtigen mich, das Erbringen der betreffenden Leistungen um die Dauer der Behinderung zzgl. einer angemessenen Anlaufzeit hinauszuschieben. Ich werde Ihnen Leistungsverzögerungen aufgrund höherer Gewalt anzeigen.
                        </li>
                        <li>
                            Setzt die Geltendmachung Ihrer Rechte die Setzung einer angemessenen Nachfrist voraus, so beträgt diese mindestens zwei (2) Wochen.
                        </li>
                    </ol>
                </li>
                <li>Nutzungsrechte
                    <ol>
                        <li>
                            Ich gewähre Ihnen aufschiebend bedingt auf die vollständige Zahlung der vereinbarten Vergütung an den erbrachten Leistungen das Recht, die Leistungen für die dem Vertrag zugrunde liegenden Zwecke im vertraglich vereinbarten Umfang zu nutzen.
                        </li>
                        <li>
                            Wollen Sie meine gestaltete Arbeiten ganz oder teilweise über den ursprünglich vereinbarten Zweck oder Umfang hinaus verwerten, bedarf es für die Abgeltung der Nutzungsrechte einer gesonderten, vorab zu treffenden Honorarabsprache.
                        </li>
                        <li>
                            Eine Weitergabe der Nutzungsrechte oder die Erteilung von Unterlizenzen ist nur zulässig, wenn sie ausdrücklich vereinbart ist oder sich aus dem Vertragszweck ergibt.
                        </li>
                        <li>
                            Ohne gesonderte Gestattung sind Sie zur Veränderung oder Bearbeitung der erbrachten Leistungen nicht berechtigt. Änderungen und Bearbeitungen, die zur Erreichung des Vertragszwecks notwendig sind, bleiben hiervon ausgenommen. Die Herausgabe von Druckdaten und Druckvorlagen ist ohne gesonderte Vereinbarung nicht geschuldet.
                        </li>
                        <li>
                            Ich habe Anspruch auf Nennung meines Namens als Urheber in Form eines Vermerks auf dem erstellten Werk. Ich darf den Copyright-Vermerk selbst anbringen, Sie sind nicht dazu berechtigt, den Urhebervermerk ohne meine Zustimmung zu entfernen. Bei nachträglichen Veränderungen haben Sie den Copyright-Vermerk entsprechend zu aktualisieren und auf die nachträgliche Veränderung hinzuweisen.
                        </li>
                    </ol>
                </li>
                <li>Referenznennung
                    <ol>
                        <li>
                            Ich bin berechtigt, Sie als Referenz auf meiner eigenen Website zu benennen und das vertragsgegenständliche Werk abzubilden. Zu diesem Zwecke kann ich Vervielfältigungen einzelner Teile herstellen, öffentlich zeigen, ausstellen, vorführen, senden oder auf sonstige Weise verwerten. Ich muss hierbei jedoch stets auf Ihre Rechte Rücksicht nehmen, hinweisen und diesen nennen. Das Recht erstreckt sich beim Webdesign auf die vertragsgegenständliche Website in der von mir abgelieferten Version sowie auf spätere Versionen, sofern der ursprüngliche Gestaltungsgehalt gegenüber den Veränderungen nicht völlig in den Hintergrund getreten ist.
                        </li>
                        <li>
                            Soweit ihrerseits wichtige Gründe der Referenznennung entgegenstehen, teilen Sie mir das bitte mit. Die Referenznennung wird dann nicht erfolgen.
                        </li>
                    </ol>
                </li>
                <li>Vergütung
                    <ol>
                        <li>
                            Es gelten die zum Zeitpunkt des Vertragsschlusses im übermittelten Angebot dargestellten Preise. Die Zahlung der vereinbarten Vergütung erfolgt nach Rechnungsstellung per Überweisung auf mein Konto. Möglich ist bei Laufzeitverträgen auch die Abbuchung per Lastschrift von Ihrem Konto.
                        </li>
                        <li>
                            Bei Rücklastschriften, die Sie zu vertreten haben, bin ich berechtigt, die hierdurch entstandenen Kosten zu berechnen. Dies gilt nicht, wenn Sie nachweisen, dass ein Schaden überhaupt nicht oder in wesentlich geringerer Höhe entstanden ist.
                        </li>
                        <li>
                            Die Rechnungen sind sofort nach Erhalt ohne Abzug zur Zahlung fällig. Hinsichtlich der Voraussetzungen und der Folgen des Verzugs gelten die gesetzlichen Regeln.
                        </li>
                    </ol>
                </li>
                <li>Gewährleistung
                    <ol>
                        <li>
                            Sie haben im Falle der Mangelhaftigkeit einer Lieferung einen Anspruch auf Nacherfüllung. Ich bin nach Ihrer Wahl zur Nacherfüllung in Form einer Mangelbeseitigung oder Lieferung/Herstellung einer neuen mangelfreien Sache verpflichtet. Im Fall der Ersatzlieferung sind Sie verpflichtet, die mangelhafte Sache zurückzugewähren.
                        </li>
                        <li>
                            Schlägt die Nacherfüllung fehl, so können Sie nach Ihrer Wahl den Preis mindern oder ohne Einhaltung einer Frist vom Vertrag zurücktreten. Dies gilt auch, wenn ich die Nacherfüllung verweigere oder die Nacherfüllung für Sie unzumutbar ist.
                        </li>
                        <li>14.3 Die Verjährungsfrist für Mängelansprüche beträgt ein (1) Jahr.</li>
                    </ol>
                </li>
                <li>Haftung
                    <ol>
                        <li>
                            In Fällen grober Fahrlässigkeit und einfacher Fahrlässigkeit bei der Verletzung wesentlicher Vertragspflichten sowie bei Geltendmachung von Schadenersatz statt der Leistung hafte ich auf den typischerweise eintretenden, vorhersehbaren Schaden. Im Übrigen ist die Haftung für leichte Fahrlässigkeit ausgeschlossen.
                        </li>
                        <li>
                            Die Haftung aus Übernahme einer Garantie oder eines Beschaffungsrisikos, aus Verzug, wegen Schäden an Leben, Körper und Gesundheit, nach dem Produkthaftungsgesetz und zwingenden gesetzlichen Bestimmungen bleibt unberührt.
                        </li>
                        <li>
                            Vorstehende Regelungen gelten auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.
                        </li>
                        <li>15.4 Sie haften nach den gesetzlichen Vorschriften.</li>
                    </ol>
                </li>
                <li>Fremdinhalte, Impressum, Domain-Namen
                    <ol>
                        <li>
                            Für Materialien und Inhalte, die Sie bereitstellen, bin ich nicht verantwortlich. Ich bin nicht verpflichtet, die Materialien und Inhalte auf mögliche Rechtsverstöße zu überprüfen. Dies betrifft insbesondere Rechtsverstöße durch den Domain-Namen sowie die Angaben im Impressum von zu erstellenden Webseiten.
                        </li>
                        <li>
                            Für den Fall, dass aufgrund der von Ihnen bereitgestellten Materialien und Inhalte ich selbst in Anspruch genommen werde, halten Sie mich schad- und klaglos.
                        </li>
                    </ol>
                </li>
                <li>Datenschutz
                    <ol>
                        <li>
                            Ich bin berechtigt, die den konkreten Auftrag betreffenden Daten zu speichern und diese Daten nach Maßgabe der gesetzlichen Bestimmungen für betrieblichen Zwecke zu verarbeiten und einzusetzen.
                        </li>
                        <li>
                            Die Weitergabe an Dritte ist zulässig, wenn und soweit dies – etwa bei der Registrierung von Domains – Gegenstand des Vertrages ist.
                        </li>
                    </ol>
                </li>
                <li>Schlussbestimmungen
                    <ol>
                        <li>
                            Erfüllungsort ist mangels anderer Vereinbarung der Ort meiner Niederlassung Hamburg.
                        </li>
                        <li>
                            Ausschließlicher Gerichtsstand für alle aus dem Vertragsverhältnis unmittelbar und mittelbar sowie über sein Entstehen und seine Wirksamkeit entspringenden Rechtsstreitigkeiten ist gegenüber Kaufleuten Hamburg. Ich habe jedoch das Recht, Sie vor Gericht an Ihrem Wohn- bzw. Geschäftssitz in Anspruch zu nehmen.
                        </li>
                        <li>
                            Für alle sich aus dem Auftrag und seiner Abwicklung ergebenden Rechtsfragen gilt deutsches Recht unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf.
                        </li>
                        <li>
                            Sollten eine oder mehrere Einzelbestimmungen dieser AGB unwirksam sein, so wird dadurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Soweit diese AGB Regelungslücken aufweisen, sollen diese durch eine Regelung gefüllt werden, die dem wirtschaftlichen Zweck des Vertrages Rechnung trägt.
                        </li>
                    </ol>
                </li>
            </ol>
            <br />
            Fassung vom 29. April 2013
        </PageWidth>
    </Layout>
)

export default Coaching
